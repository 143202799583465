import React from 'react';
import ButtonComponent from '../button/button.component';
import HeadingComponent from '../heading/heading.component';
export type exitcomponenttype = {
    title?: string;
    onExit?: () => void;
    onCancel?: () => void;
}
const ExitComponent = (props: exitcomponenttype) => {
    return (
        <>
            <HeadingComponent align="text-center" fontSize={20} color="#150035" title={props.title} />
            <div className="flex justify-center p-5 mt-5 w-full">
                <div className="w-32 ml-4">
                    <ButtonComponent onClick={props.onCancel} bg="bg-white text-primary hover:bg-primary-hover hover:text-white" className="border border-primary rounded-lg" title="خیر" />
                </div>
                <div className="w-32">
                    <ButtonComponent onClick={props.onExit} bg="bg-white text-primary hover:bg-primary-hover hover:text-white" className="border border-primary rounded-lg" title="بله" />
                </div>
            </div>
        </>
    )
}

export default ExitComponent;