import React, { useEffect } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip } from "victory";

export type ChartComponentProps = {
  yLabel?: string;
  xLabel?: string;
  data?: any[]
}

const ChartComponent = (props: ChartComponentProps) => {

  useEffect(() => {

    console.log('====================================');
    console.log(props.data);
    console.log('====================================');
  }, [])
  return (
    <div style={{ direction: "ltr" }}>
      <VictoryChart
      height={250}
        domainPadding={{ x: 40, y: 0 }} >
        <VictoryBar
          data={props.data}
          x="month"
          y="price"
          labels={({ datum }) => `${datum.price} تومن`}
          // labelComponent={<VictoryTooltip  />}
          labelComponent={<VictoryTooltip style={{stroke: "red", strokeWidth: 0}} constrainToVisibleArea />}

          cornerRadius={3}
          // padding={10}
          style={{
            data: {
              fill: "#F38E36",
              width: 40,
              fontFamily: "IRANSans",
            }
          }}
        />
        <VictoryAxis
          label={props.xLabel}
          style={{
            axisLabel: { padding: 30, fontFamily: "IRANSans" }
          }}
        />
        <VictoryAxis dependentAxis
          label={props.yLabel}
          style={{
            axisLabel: { padding: 40, fontFamily: "IRANSans" }
          }}
        />
      </VictoryChart>
    </div>
    //   <VictoryChart
    //   theme={VictoryTheme.material}
    // >
    //   <VictoryAxis/>
    //   <VictoryBar
    //       data={[
    //         { experiment: "trial 1", expected: 3.75, actual: 3.21 },
    //         { experiment: "trial 2", expected: 3.75, actual: 3.38 },
    //         { experiment: "trial 3", expected: 3.75, actual: 2.05 },
    //         { experiment: "trial 4", expected: 3.75, actual: 3.71 }
    //       ]}
    //       x="experiment"
    //       y={(d) => (d.actual / d.expected) * 100}
    //     style={{ data: { fill: "#c43a31", stroke: "black", strokeWidth: 2 }}}
    //   />
    // </VictoryChart>

  )
}
export default ChartComponent;