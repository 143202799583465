import React from 'react';
import { SelectModel } from '../../models/select/select.model';
import ProfileboxsComponent from '../profile-box/profile-box.component';
import SelectComponent from '../select/select.component';

export type NavigationComponentProps = {
    display_name?: string;
    sub_title?: string;
    options?: SelectModel[];
    img?: string;
    default?: string;
    onSelect?: (value: SelectModel) => void;
    onFilter?: () => void;
    onClear?: () => void;
    filterTitle?: string;
}

const NavigationComponent = (props: NavigationComponentProps) => {

    const onSelect = (value: SelectModel) => {
        if (props.onSelect) {
            props.onSelect(value);
        }
    }
    
    return (
        <div className="grid grid-cols-1 gap-4 md:grid md:grid-cols-3">
            <div className="md:mr-0">
                <SelectComponent default={props.default} options={props.options} onSelect={onSelect} />
            </div>
            <div className="flex items-center justify-center sm:justify-start">
                <span  onClick={props.onFilter} className="flex border rounded p-1 bg-gray-200 cursor-pointer px-3">
                    <img style={{ width: 14, height: 14 }} className="mt-1.5" src="imags/filter_list.svg" />
                    <span className="mr-2">فیلتر</span> 
                </span>
                
                {props.filterTitle !== "" && <span onClick={props.onClear} className="mr-2 border rounded p-1">{props.filterTitle ?? ''} </span> }
            </div>
            <div className="m-auto md:ml-0">
                <ProfileboxsComponent img={props.img} display_name={props.display_name} sub_title={props.sub_title} />
            </div>
        </div>
    )
}
export default NavigationComponent;