import { ReportModel } from './../../models/report/report.model';
import AxiosHelper from "../../axios/axios.helper";
import ResponseModel from "../../models/response.model";
import { setModal, startLoading, stopLoading } from '../slice/global.slice';
import { setReports } from '../slice/product.slice';
import { REPORTDETAILURL } from '../../axios/url.helper';
import { FilterModel } from '../../models/filter/filter.model';

export function reportsRequest(product_id?: number, filter?: FilterModel) {

    return async (dispatch: any) => {

        dispatch(startLoading());

        let url = REPORTDETAILURL;

        let params = [];
        // debugger;
        if(product_id && product_id !== -1) {
            params.push(`product_id=${product_id}`);
        }

        if(filter?.range) {
            params.push(`range=${filter?.range}`);
        }

        if(filter?.start_date) {
            params.push(`start_date=${filter?.start_date}`);
        }

        if(filter?.end_date) {
            params.push(`end_date=${filter?.end_date}`);
        }

        if(params.length > 0) {
            url = `${url}?${params.join("&")}`;
        }
        
        try {
            let response = await AxiosHelper.get<ResponseModel<ReportModel>>(url);
            if (response.status === 200) {
                dispatch(setReports(response.data.data ?? {}))
            }

        } catch (error) {
            dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            dispatch(stopLoading());
        }
    };
}