import { applyMiddleware } from '@reduxjs/toolkit';
import { createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import authSlice, { AuthStateModel } from './slice/auth.slice';
import globalSlice, { GlobalStateModel } from './slice/global.slice';
import productSlice, { ProductStateModel } from './slice/product.slice';
import testSlice, { TestStateModel } from './slice/test.slice';
// import lessonsSlice, { LessonsStateModel } from './slice/lessons.slice';
// import termsSlice, { TermsStateModel } from './slice/terms.slice';
// import wordsSlice, { WordsStateModel } from './slice/words.slice';

export interface CombineReducersModel {
    global: GlobalStateModel,
    auth: AuthStateModel,
    products: ProductStateModel,
    test: TestStateModel
    // terms: TermsStateModel,
    // lessons: LessonsStateModel,
    // words: WordsStateModel
}

const rootReducer = combineReducers<CombineReducersModel>({
    global: globalSlice,
    auth: authSlice,
    products: productSlice,
    test: testSlice
    // terms: termsSlice,
    // lessons: lessonsSlice,
    // words: wordsSlice
});

const middleware = applyMiddleware(thunk);

// Note: this API requires redux@>=3.1.0
const store = createStore(rootReducer, middleware);



export default store;
