import React from 'react';
import Paragraphcomponent from '../paragraph/paragraph.component';

export type InputComponentProps = {
    color?: string;
    title?: string;
    type?: "text" | "password" | "number";
    onChange?: (value: string) => void;
    className?: string;
    value?: string;
}


const InputComponent = (props: InputComponentProps) => {

    const onChange = (value: string) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const inputClass = ["outline-none rounded border p-2 mt-2 w-full"];

    if(props.className) {
        inputClass.push(props.className)
    }

    return (
        <div>
            <Paragraphcomponent title={props.title} />
            <input
                type={props.type ?? "text"} 
                onChange={(event) => onChange(event.target.value)} 
                className={inputClass.join(" ")} 
                style={{ color: props.color }}
                value={props.value}
            />
        </div>
    )
}
export default InputComponent;