import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterDayModel, FilterModel } from '../../models/filter/filter.model';
import { SelectModel } from '../../models/select/select.model';
import { globalState, resetModal, setFilter } from '../../redux/slice/global.slice';
import { setModal } from '../../redux/slice/global.slice';
import { filterThunk } from '../../redux/thunk/filter.thunk';
import ButtonComponent from '../button/button.component';
import Calender from '../calender/calender';
import HeadingComponent from '../heading/heading.component';
import ModalComponent from '../modal/modal.component';
export type filtercomponenttype = {
    title?: string;
    selected?: boolean;
    // fromDate?: string;
    // toDate?: string;
    onFromDate?: () => void;
    onToDate?: () => void;
    onClear?: () => void;
    onFilter?: (filter?: FilterModel, selectedProduct?: SelectModel) => void;
    children?: React.ReactNode;
    titleButton?: string;

}
const FilterComponent = (props: filtercomponenttype) => {
    const buttonClass = "w-full h-6 bg-white border border-primary h-16 rounded-lg text-xs text-white focus:outline-none hover:bg-primary";
    // const [selected, setselected] = useState<FilterDayModel>("");
    // const [calendar, setCalendar] = useState<boolean>(false);
    // const [check, setCheck] = useState(false);

    // const [selected, setselected] = useState<Number>(0);


    const dispatch = useDispatch();
    const { filter, selectedProduct } = useSelector(globalState)

    // useEffect(() => {
    //     console.log('==================================== props.fromDate ');
    //     console.log(props.fromDate);
    //     console.log('====================================');
    // }, [props.fromDate, props.toDate])

    // const [fromDate, setFromDate] = useState<string>(props.fromDate ?? "از تاریخ");
    // const [toDate, setToDate] = useState<string>(props.toDate ?? "تا تاریخ");

    const menu: { title?: string, name?: FilterDayModel }[] = [
        {
            title: "امروز",
            name: "today"
        },
        {
            title: "هفت روز گذشته",
            name: "7day"
        },
        {
            title: "ماه جاری",
            name: "month"
        },
        {
            title: "ماه گذشته",
            name: "last_month"
        },
        {
            title: "سال گذشته",
            name: "year"
        },
        {
            title: "تاریخ",
            name: "custom"
        },
    ]
    const onSelected = (name: FilterDayModel) => {
        // setselected(name);
        console.log('====================================');
        console.log(name);
        console.log('====================================');
        dispatch(setFilter({ ...filter, range: name }))
        // dispatch(filterThunk({...filter, type: name}));
    }

    return (
        <div className="relative">
            <img style={{ width: 14, height: 14 }} className="absolute top-0 left-5 cursor-pointer" onClick={() => dispatch(resetModal())} src="imags/close.svg" />
            <div>
                <HeadingComponent align="text-center" fontSize={14} color="#150035" title={props.title} />
            </div>
            <div className="flex justify-between p-5">

                {menu.map((item) => {
                    return (
                        <div className="w-full mx-2">
                            <button onClick={() => onSelected(item.name ?? "today")}
                                className={buttonClass + (filter?.range === item.name ? " bg-primary text-xs text-white" : "border-primary rounded-lg text-xs text-yellow-500")}>
                                <p className="text-center font-bold">{item.title}</p>
                            </button>
                        </div>
                    )
                })}
            </div>
            {filter?.range === "custom" &&
                <div className="flex items-center justify-center mb-6">
                    <div className="w-40">
                        <ButtonComponent onClick={props.onFromDate} color="#A1A4B2" bg="bg-gray-200" className="rounded-full" title={(filter && filter.start_date) ?? "از تاریخ"} />
                    </div>
                    <div className="w-40 mr-2.5">
                        <ButtonComponent onClick={props.onToDate} color="#A1A4B2" bg="bg-gray-200" className="rounded-full" title={(filter && filter.end_date) ?? "تا تاریخ"} />
                    </div>
                </div>
            }
            <div className="px-4 py-3 sm:px-6 justify-center flex w-full sm:w-80 mx-auto">
                <ButtonComponent bg="bg-red-500 hover:bg-red-800 rounded-md text-white" disabled={!filter} title="فیلتر" onClick={() => {
                    if (props.onFilter) {
                        if(filter?.range === "custom") {
                            if(!filter.start_date || !filter.end_date) {
                                alert("لطفا تاریخ شروع و پایان را انتخاب کنید");
                                return;
                            }
                        }
                        props.onFilter(filter, selectedProduct)
                    }
                }} />
                <ButtonComponent bg="border text-gray-600 rounded-md text-white mr-2" title="حذف فیلتر" onClick={() => {
                    if (props.onClear) {
                        props.onClear();
                    }
                }} />
            </div>
        </div>
    )
}
export default FilterComponent;