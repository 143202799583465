import React from 'react'

export type ParagraphComponentProps = {
    title?: string;
    color?: string;
    fontSize?: number;
}

const ParagraphComponent = (props: ParagraphComponentProps) => {
    return (
        <p style={{ color: props.color, fontSize: props.fontSize }}>{props.title}</p>
    )
}

export default ParagraphComponent;