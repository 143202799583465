import React from "react";
import history from "../../redux/history";
import HeadingComponent from "../heading/heading.component";
import PaginationComponent from "../pagination/pagination.component";

type BoxContainerProps = {
    header?: string;
    footer?: string;
    footer_click?: () => void;
    children?: React.ReactNode;
    src?: string;
    secondHeader?: string;
    paginationComponent?: string;
}

const BoxContainerComponent = (props: BoxContainerProps) => {

    const more = () => {
        if (props.footer_click) {
            props.footer_click();
        }
    }

    return (
        <div className="p-5 bg-white border rounded-lg">
            <div className="relative flex items-center">
                <img className="bg-gray-light rounded-full p-1" style={{ width: 32, height: 32 }} src={props.src} />

                <div className="mr-3">
                    <HeadingComponent fontSize={16} color="#343A40" title={props.header} />
                    {
                        props.secondHeader && <HeadingComponent fontSize={14} color="#868E96" title={props.secondHeader} />
                    }
                </div>
                {/* <img className="absolute  left-0" src="imags/menu.png" /> */}
            </div>
            <div className="h-0.5 mt-5 w-full bg-gray-light"></div>
            <div className="bg-white relative w-full">
                {props.children}
            </div>

            {props.footer &&
                <div>
                    <div className="line"></div>
                    <div className="p-5  relative">
                        <div className="absolute left-0 flex cursor-pointer" onClick={more}>
                            <HeadingComponent fontSize={16} color="#343A40" title={props.footer} />
                            <img style={{ width: 14, height: 14 }} className="mr-2 mt-1.5" src="imags/chevron.svg" />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default BoxContainerComponent;