import React from 'react';
import HeadingComponent from '../heading/heading.component';
import ParagraphComponent from '../paragraph/paragraph.component';

export type LogoTextComponentProps = {
    heading?: string;
    description?: string;
}

const LogoTextComponent = (props: LogoTextComponentProps) => {
    return (
        <div className="flex">
            <div>
                <img className="mt-2" src="imags/logo.png"></img>
            </div>
            <div className="mr-2">
                <HeadingComponent fontSize={24} color="#F38E36" align="text-right" title={props.heading} />
                <ParagraphComponent fontSize={12} color="rgba(0, 0, 0, 0.4)" title={props.description} />
            </div>
        </div>
    )
}
export default LogoTextComponent;