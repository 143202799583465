import React from 'react';
import Headingcomponent from '../heading/heading.component';
import Paragraphcomponent from '../paragraph/paragraph.component';

export type ProfileboxsComponentProps = {
  display_name?: string;
  sub_title?: string;
  img?: string;
}

const ProfileboxsComponent = (props: ProfileboxsComponentProps) => {
  return (
    <div className="flex float-left">
      <div className="space-y-1 ml-3 flex justify-center items-center">
        <Headingcomponent color="#343A40" fontSize={20} title={props.display_name} />
        {props.sub_title && <Paragraphcomponent fontSize={12} color="#868E96" title={props.sub_title} />}
      </div>
      <div dangerouslySetInnerHTML={{ __html: props.img ?? "" }} className="rounded-full overflow-auto" style={{ width: 40 }}></div>
    </div>
  )
}
export default ProfileboxsComponent;