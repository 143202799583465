import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CombineReducersModel } from '..';
import { FilterModel } from '../../models/filter/filter.model';
import { ModalModel } from '../../models/modal.model';
import { SelectModel } from '../../models/select/select.model';
import { SideBarModel } from '../../models/sidebar/sidebar-item.model';
// import { ToastModel } from '../../models/error.model';

export interface GlobalStateModel {
  isLogin: boolean;
  isLoading: boolean;
  modal: ModalModel | undefined;
  selectedMenu?: string;
  selectedProduct?: SelectModel;
  filter?: FilterModel
}

const initialState: GlobalStateModel = { isLogin: false, isLoading: false, modal: undefined, selectedMenu: undefined, selectedProduct: undefined };

const GlobalState = createSlice({
  name: 'GlobalState',
  initialState,
  reducers: {
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setModal: (state, action: PayloadAction<ModalModel | undefined>) => {
      state.isLoading = false;
      state.modal = action.payload;
    },
    resetModal: (state) => {
      state.modal = undefined;
    },
    setSelectedMenu: (state, action: PayloadAction<string>) => {
      state.selectedMenu = action.payload;
    },
    setSelectedProduct: (state, action: PayloadAction<SelectModel>) => {
      state.selectedProduct = action.payload;
    },
    setFilter: (state, action: PayloadAction<FilterModel>) => {
      state.filter = action.payload;
    },
    clearFilter: state => {
      state.filter = undefined;
    },
  },
});

export const globalState = (state: CombineReducersModel) => state.global;
// setToast
export const { setIsLogin, startLoading, stopLoading, resetModal, setModal, setSelectedMenu, setSelectedProduct, setFilter, clearFilter } = GlobalState.actions;
export default GlobalState.reducer;

