import React from 'react';
import { SizeModal } from '../../models/modal.model';
import ButtonComponent from '../button/button.component';
import HeadingComponent from '../heading/heading.component';
import "./modal.component.scss";

type ModalComponentProps = {
    title?: string;
    message?: string;
    children?: React.ReactNode;
    className?: string;
    close?: () => void;
    size?: SizeModal
}

const ModalComponent = (props: ModalComponentProps) => {
    const close = () => {
        if (props.close) {
            props.close();
        }
    }
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className={"relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:pt-8 sm:align-middle " + (props.className) + " " + (props.size ?? "max-w-lg")}>
                    <span className="bar bg-red-600 absolute left-0 top-0 z-2 w-full h-2"></span>
                    {props.children ?
                        <div className="pt-5 pb-4">
                            {props.children}
                        </div>
                        :
                        <div>
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <HeadingComponent fontSize={24} title={props.title} align="text-center" />
                                    <div className="py-10">
                                        <HeadingComponent title={props.message} align="text-center" />
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-3 sm:px-6 justify-center flex w-full sm:w-2/5 mx-auto">
                                <ButtonComponent bg="bg-red-600 hover:bg-red-800 rounded-md" onClick={close} title="متوجه شدم" />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}

export default ModalComponent;