import { SelectModel } from '../../models/select/select.model';
import { FilterModel } from './../../models/filter/filter.model';
import { customersRequest } from './customer.thunk';
import { reportsRequest } from './report.thunk';

export function filterThunk(filter?: FilterModel, selectedProduct?: SelectModel, page = 0, number = 10) {

    return async (dispatch: any) => {

        // dispatch(startLoading());

        // debugger;

        const product_id = Number(selectedProduct?.value);
        dispatch(reportsRequest(!!product_id ? product_id : -1, filter));


        dispatch(customersRequest(page, number, !!product_id ? product_id : -1, filter))

        try {


        } catch (error) {
            // dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            // dispatch(stopLoading());
        }
    };
}