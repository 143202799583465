import axios from "axios";
import { useDispatch } from "react-redux";
import { ACCESSTOKEN } from "../constant/auth.constant";
import { setIsLogin } from "../redux/slice/global.slice";
import { BASEURL } from "./url.helper";


const AxiosHelper = axios.create({
    baseURL: BASEURL,
    timeout: 600000,
});
const UNAUTHORIZED = 401;
// const LOG = false;
// Add a request interceptor
AxiosHelper.interceptors.request.use(async (request) => {

    const accessToken = localStorage.getItem(ACCESSTOKEN);
    request.headers.Authorization = `Bearer ${accessToken}`;
    // if (LOG === true) {
    //     console.log('==================================== request ');
    //     console.log(JSON.stringify(request));
    //     console.log('====================================');
    // }
    return request;
});
AxiosHelper.interceptors.response.use(async (response) => {
    // if (LOG === true) {

    //     console.log('==================================== response ');
    //     console.log(JSON.stringify(response));
    //     console.log('====================================');
    // }

    return response;
}, async (error) => {
    // if (LOG === true) {

    //     console.log('==================================== error ');
    //     console.log(JSON.stringify(error.response));
    //     console.log('====================================');
    // }
    
    // const { status } = error.response;
    // debugger;
    // if(status === UNAUTHORIZED) {
        // alert(11)
    //     localStorage.clear();
    //     document.location.href = "/";
    //     // const dispatch = useDispatch();
    //     // dispatch(setIsLogin(false));    
    // }
    
    return Promise.reject(error);
    // return error;
});


export default AxiosHelper;