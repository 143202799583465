import { FilterModel } from './../../models/filter/filter.model';
import AxiosHelper from "../../axios/axios.helper";
import ResponseModel from "../../models/response.model";
import { setModal, startLoading, stopLoading } from '../slice/global.slice';
import { setCustomers } from '../slice/product.slice';
import { CUSTOMERSURL } from '../../axios/url.helper';
import { CustomerModel } from '../../models/customer/customer.model';

export function customersRequest(page: number = 0, number: number = 10, product_id: number = -1, filter?: FilterModel) {

    return async (dispatch: any) => {

        dispatch(startLoading());

        // let url = `${CUSTOMERSURL}?page=${page}&product_id=${product_id}&number=${number}&start_date=${start_date}&end_date=${end_date}`;
        let url = "";

        let params = [];
        // debugger;
        if(product_id && product_id !== -1) {
            params.push(`product_id=${product_id}`);
        }

        if(page) {
            params.push(`page=${page}`);
        }

        if(number) {
            params.push(`number=${number}`);
        }

        if(filter?.range) {
            params.push(`range=${filter?.range}`);
        }

        if(filter?.start_date) {
            params.push(`start_date=${filter?.start_date}`);
        }

        if(filter?.end_date) {
            params.push(`end_date=${filter?.end_date}`);
        }

        if(params.length > 0) {
            url = `${CUSTOMERSURL}?${params.join("&")}`;
        }


        try {
            let response = await AxiosHelper.get<ResponseModel<{customers: CustomerModel[], count: number}>>(url);
            if (response.status === 200) {
                const data = response.data.data;
                dispatch(setCustomers({customers: data?.customers ?? [], count: data?.count ?? 0}));
            }

        } catch (error) {
            dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            dispatch(stopLoading());
        }
    };
}