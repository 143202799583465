import React, { useState } from 'react';
import Select from 'react-select';


export type PaginationComponenttype = {
    onNext?: () => void;
    onPrev?: () => void;
    page?: number;
    total?: number;
}

const PaginationComponent = (props: PaginationComponenttype) => {

    const onNext = () => {

        if(props.total === props.page) {
            return;
        }

        if (props.onNext) {
            props.onNext();
        }
    }

    const onPrev = () => {

        if(props.page === 1) {
            return;
        }

        if (props.onPrev) {
            props.onPrev();
        }
    }

    return (

        <div className="grid grid-cols-2 gap-4">
            <div>
                {/* <div className="flex  flex-row-reverse space-x-4 float-right">

                    <p className="mt-1.5">Rows per page</p>
                    <Select className="w-18"
                        options={options}
                        placeholder="15"

                    />

                </div> */}
            </div>
            <div>
                <div className="flex flex-row-reverse space-x-4">
                    <div className="box-border w-12 p-3 border-2 rounded-md cursor-pointer" onClick={onNext}>
                        <img className="mr-1" src="imags/Vector (Stroke).png"></img>
                    </div>
                    <div className="box-border w-12 p-2 border-2">
                        <p className="text-blue-500 text-center"><b>{props.page ? (props.page) : 1}</b></p>
                    </div>
                    <p className="text-gray-300 mt-3"><b>/</b></p>
                    <p className="mt-3"><b>{props.total}</b></p>
                    <div className="box-border w-12 p-3 border-2 rounded-md cursor-pointer"  onClick={onPrev}>
                        <img className="mr-1" src="imags/flash-right.png"></img>
                    </div>
                </div>
            </div>

        </div>

    )
}
export default PaginationComponent;