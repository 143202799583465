import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxContainerComponent from '../../components/box-container/box-container.component';
import Calender from '../../components/calender/calender';
import ChartComponent from '../../components/chart/chart.component';
import ExitComponent from '../../components/exitComponent/ExitComponent';
import { filterName } from '../../components/FilterComponent/filter.helper';
import FilterComponent from '../../components/FilterComponent/FilterComponent';
import ModalContainer from '../../components/ModalContainer/modal-container';
import ReportboxsComponent from '../../components/report-box/report-box.component';
import TableComponent from '../../components/table/table.component';
import { CustomersRoute, DashboardRoute, LoginRoute } from '../../constant/route.constant';
import { UserHelper } from '../../helper/user.helper';
import history from '../../redux/history';
import { globalState, setSelectedMenu } from '../../redux/slice/global.slice';
import { productState } from '../../redux/slice/product.slice';
import { customersRequest } from '../../redux/thunk/customer.thunk';
import { filterThunk } from '../../redux/thunk/filter.thunk';
import { productsRequest } from '../../redux/thunk/product.thunk';
import { reportsRequest } from '../../redux/thunk/report.thunk';
import MasterPage from '../master/master.page';

export type DashboardPageType = {

}

const DashboardPage = (props: DashboardPageType) => {

    const dispatch = useDispatch();
    const { reports, customers } = useSelector(productState);
    const { selectedProduct, filter } = useSelector(globalState);


    useEffect(() => {

        const userHelper = new UserHelper();
        const accessToken = userHelper.getAccessToken();
        if (!!accessToken === false) {
            history.replace(LoginRoute);
            return;
        }

        // const product_id = Number(selectedProduct?.value);

        dispatch(setSelectedMenu(DashboardRoute));

        /**
         * درخواست لیست محصولات
         */
        dispatch(productsRequest());
        /**
         * درخواست گزارش همه محصولات
         */
        // dispatch(reportsRequest(!!product_id ? product_id : -1, filter));
        dispatch(filterThunk(filter, selectedProduct));

        /**
         * دریافت لیست سفارشات ثبت شده
         */
        // dispatch(customersRequest(0, 10, !!product_id ? product_id : -1))

    }, [])

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         console.log('====================================');
    //         console.log(filter);
    //         console.log(selectedProduct);
    //         console.log('====================================');
    //         dispatch(filterThunk(filter, selectedProduct))
    //     }, 10000)
    //     return () => {
    //         clearInterval(interval);
    //     }
    // }, [filter])

    // useEffect(() => {
    //     console.log('====================================');
    //     console.log(filter);
    //     console.log('====================================');
    //     const product_id = Number(selectedProduct?.value);
    //     dispatch(reportsRequest(!!product_id ? product_id : -1, filter?.type));
    // }, [filter?.type])


    const gregorian_to_jalali = (gy: number, gm: number, gd: number) => {
        var g_d_m, jy, jm, jd, gy2, days;
        g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
        gy2 = (gm > 2) ? (gy + 1) : gy;
        days = 355666 + (365 * gy) + ~~((gy2 + 3) / 4) - ~~((gy2 + 99) / 100) + ~~((gy2 + 399) / 400) + gd + g_d_m[gm - 1];
        jy = -1595 + (33 * ~~(days / 12053));
        days %= 12053;
        jy += 4 * ~~(days / 1461);
        days %= 1461;
        if (days > 365) {
            jy += ~~((days - 1) / 365);
            days = (days - 1) % 365;
        }
        if (days < 186) {
            jm = 1 + ~~(days / 31);
            jd = 1 + (days % 31);
        } else {
            jm = 7 + ~~((days - 186) / 30);
            jd = 1 + ((days - 186) % 30);
        }
        return [jy, jm, jd];
    }

    const jalali_to_gregorian = (jy: number, jm: number, jd: number) => {
        var sal_a, gy, gm, gd, days;
        jy += 1595;
        days = -355668 + (365 * jy) + (~~(jy / 33) * 8) + ~~(((jy % 33) + 3) / 4) + jd + ((jm < 7) ? (jm - 1) * 31 : ((jm - 7) * 30) + 186);
        gy = 400 * ~~(days / 146097);
        days %= 146097;
        if (days > 36524) {
            gy += 100 * ~~(--days / 36524);
            days %= 36524;
            if (days >= 365) days++;
        }
        gy += 4 * ~~(days / 1461);
        days %= 1461;
        if (days > 365) {
            gy += ~~((days - 1) / 365);
            days = (days - 1) % 365;
        }
        gd = days + 1;
        sal_a = [0, 31, ((gy % 4 === 0 && gy % 100 !== 0) || (gy % 400 === 0)) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        for (gm = 0; gm < 13 && gd > sal_a[gm]; gm++) gd -= sal_a[gm];
        return [gy, gm, gd];
    }

    const timeConverter = (timestamp: number) => {
        const date = new Date(Number(timestamp));
        var month = date.getUTCMonth() + 1; //months from 1-12
        var day = date.getUTCDate();
        var year = date.getUTCFullYear();
        var persian = gregorian_to_jalali(year, month, day);
        return persian[2] + "";
    }

    return (
        <MasterPage isCustomer={true} isReport={true} >

            <ReportboxsComponent model={[
                {
                    title: (reports?.total_items ?? 0).toLocaleString() + "",
                    subtitle: "تعداد فروش"
                },
                {
                    title: (reports?.total_sales ?? 0).toLocaleString() + "",
                    subtitle: "کل فروش",
                    unit: "تومان"
                },
                {
                    title: (reports?.user_sales ?? 0).toLocaleString() + "",
                    subtitle: "سهم شما - " + (Math.round((reports?.percent ?? 0) * 100)) + "%",
                    unit: "تومان"
                },
            ]} />



            <BoxContainerComponent paginationComponent="1" src="imags/drag.png" secondHeader="سفارشات ثبت شده در بانی کستر" header="لیست سفارشات" footer="بیشتر "
                footer_click={() => {
                    history.push(CustomersRoute);
                }}
            // footer_route="/customers"
            >
                <TableComponent model={
                    customers?.map((customer, index) => {
                        const product = (customer.products && customer.products?.length >= 0) ? customer.products[0] : {};
                        return {
                            display_name: customer.user?.display_name,
                            product_name: product.name,
                            date: product.date,
                            price: (product.price ?? "").toLocaleString() + " تومان",
                            image: customer.user?.image
                        }
                    })
                } />
                {
                    // props.paginationComponent && <div className="w-full bg-white mt-8 mb-8 ">
                    //     <PaginationComponent />
                    // </div>
                }
            </BoxContainerComponent>

            <BoxContainerComponent src="imags/header_icone.svg" header={`نمودار فروش هفت روز گذشته`}>
                <ChartComponent
                    xLabel="روز"
                    yLabel="فروش"
                    data={
                        reports?.order_item_amounts?.map((item, index) => {
                            return {
                                month: timeConverter(item[0]),
                                price: item[1],
                            }
                        })
                    } />
            </BoxContainerComponent>

            {/* <BoxContainerComponent src="imags/dragzarin.png" header="جزئیات سفارش" >
                <DetailOrderCompnent paragraph="سازمان امور عشایر ایران، سازمان انتقال خون ایران، سازمان برنامه و بودجه کشور، سازمان بهزیستی کشور، سازمان پزشکی قانونی کشور، سازمان ثبت اسناد و املاک کشور، سازمان حسابرسی، سازمان حفاظت محیط زیست، سازمان دامپزشکی کشور، سازمان فرهنگ و ارتباطات اسلامی، سازمان قضایی نیروهای مسلح، سازمان ملی استاندارد، سازمان هواشناسی کشور، شرکت فرودگاه ها و ناوبری هوایی ایران
            (مادر تخصصی)، وزارت تعاون، کار و رفاه اجتماعی، وزارت جهاد کشاورزی، وزارت جهاد کشاورزی (به کارگیری قراردادی)، وزارت کشور، سازمان نوسازی، توسعه و ت
            جهیز مدارس کشور، مرکز آموزش مدیریت دولتی، سازمان ملی بهره وری ایران، سازمان اداری و استخدامی کشور و
             دستگاه های مربوط به سازمان مدیریت و برنامه ریزی ا
             ستان سیستان و بلوچستان شامل: اداره کل تعاون، کار و امور ا
             جتماعی استان سیستان و بلوچستان، اداره کل استانداردر ه" titleBottomImg="امین قینرلو" />
            </BoxContainerComponent> */}
            {/* <ModalContainer size="sm:w-full">
                <FilterComponent title="لطفا حالت فیلتر خود را انتخاب کنید" />
            </ModalContainer>

            <Calender/> */}

        </MasterPage>
    )
}
export default DashboardPage;
