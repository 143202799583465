import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calender from '../../components/calender/calender';
import { filterName } from '../../components/FilterComponent/filter.helper';
import FilterComponent from '../../components/FilterComponent/FilterComponent';
import ModalComponent from '../../components/modal/modal.component';
import NavigationComponent from '../../components/navigation/Navigation.Component';
import SiderComponent from '../../components/slider/slider.component';
import { FilterModel } from '../../models/filter/filter.model';
import { SelectModel } from '../../models/select/select.model';
import { authState } from '../../redux/slice/auth.slice';
import { clearFilter, globalState, resetModal, setFilter, setModal, setSelectedProduct } from '../../redux/slice/global.slice';
import { productState } from '../../redux/slice/product.slice';
import { customersRequest } from '../../redux/thunk/customer.thunk';
import { filterThunk } from '../../redux/thunk/filter.thunk';
import { reportsRequest } from '../../redux/thunk/report.thunk';

type MasterPageProps = {
    children?: React.ReactNode;
    isCustomer?: boolean;
    isReport?: boolean;
    limit?: number;
    page?: number;
}

const MasterPage = (props: MasterPageProps) => {

    const { products } = useSelector(productState);
    const { selectedProduct, filter } = useSelector(globalState);
    const { user } = useSelector(authState);
    const dispatch = useDispatch();
    // const [date, setDate] = useState<{fromDate?: string, toDate?: string}>();
    const [calendarToDate, setCalendarToDate] = useState(false);
    const [calendarFromDate, setCalendarFromDate] = useState(false);

    /**
     * در صورتی که محصولی انتخاب شده باشد گزارش مربوط به یک محصول از سمت سرور گرفته می‌شود.
     * @param value 
     */
    const onSelectProduct = (value: SelectModel) => {
        dispatch(setSelectedProduct(value));
        // if (props.isCustomer) {
        //     dispatch(customersRequest(props.page ?? 0, props.limit ?? 10, Number(value.value)))
        // }
        // if (props.isReport) {
        //     dispatch(reportsRequest(Number(value.value)));
        // }
        dispatch(filterThunk(filter, value, props.page, props.limit))

    }

    const onFilter = () => {
        dispatch(setModal({ children: <FilterComponent onFilter={_onFilterButton} onClear={_clearFilter} onFromDate={onCalendar} onToDate={onToDateCalendar} title="لطفا حالت فیلتر خود را انتخاب کنید" />, size: "max-w-4xl" }))
    }

    const onCalendar = () => {
        setCalendarFromDate(true);
    }

    const onToDateCalendar = () => {
        setCalendarToDate(true);
    }

    const _onFilterButton = (filter?: FilterModel, selectedProduct?: SelectModel) => {
        console.log(filter);
        console.log(selectedProduct);
        
        dispatch(resetModal());
        dispatch(filterThunk(filter, selectedProduct))
    }

    const _clearFilter = () => {
        dispatch(resetModal());
        dispatch(clearFilter());
        dispatch(filterThunk(undefined, selectedProduct))
    }

    return (
        <div className="container mx-auto">

            { calendarFromDate && <ModalComponent className="" size="" children={<Calender onSelect={(val) => {
                dispatch(setFilter({ ...filter, start_date: val }))
                setCalendarFromDate(false);
            }} />} />}

            { calendarToDate && <ModalComponent className="" size="" children={<Calender onSelect={(val) => {
                dispatch(setFilter({ ...filter, end_date: val }))
                setCalendarToDate(false);
            }} />} />}

            <div className="flex flex-col md:flex md:flex-row">
                <div className="sm:ml-2 w-full sm:bg-white rounded mb-2 md:w-1/5 sm:h-screen border">
                    <SiderComponent />
                </div>
                <div className="flex-1">
                    <div className="rounded space-y-2">
                        <div className="bg-white p-5 w-full border rounded">
                            <NavigationComponent
                                filterTitle={filterName(filter)}
                                // onClear={_clearFilter}
                                onSelect={onSelectProduct}
                                display_name={user?.display_name}
                                img={user?.image}
                                options={
                                    products?.map((item) => {
                                        return {
                                            value: item.id + "",
                                            label: item.title ?? ""
                                        }
                                    })
                                }
                                onFilter={onFilter}
                                default={selectedProduct?.value}
                            />
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MasterPage;