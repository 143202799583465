// export const BASEURL = "http://localhost:8000/v1/";
// export const BASEURL = "http://localhost/banicaster/api-dashboard/";
export const BASEURL = "https://banicaster.com/api-dashboard/";

// http://localhost:8000/v1/lessons/search

// export const login = (number: string) => `timeout-test/${number}`;
export const LOGIN_URL = `v1/auth/login`;

/**
 * product/lis
 * لیست محصولا مربوط به کاربر
 */
export const PRODUCTLIST_URL = `v1/product/list`;

/**
 * گزارش
 */
export const REPORTDETAILURL = `v1/report/detail`

/**
 * لیست مشتریان
 */
export const CUSTOMERSURL = `v1/customer/list`;


/**
 * کپچا
 */
export const CAPTCHAURL = "v1/captcha"

/**
 * terms
 */
// export const termsUrl = `terms/list`;
// export const termsInsertUrl = `terms/insert`;
// export const termsUpdateUrl = `terms/update`;

// /**
//  * lessons
//  */
//  export const lessonsUrl = (term_id: number) => `lessons/list/${term_id}`;
//  export const lessonsInsertUrl = `lessons/insert`;
//  export const lessonsUpdateUrl = `lessons/update`;
//  export const lessonsSearchUrl = `lessons/search`;

//  /**
//  * words
//  */
//   export const wordsUrl = (lesson_id: number) => `words/list/${lesson_id}`;
//   export const wordsInsertUrl = `words/insert`;
//   export const wordsUpdateUrl = `words/update`;
