import React from "react";
import "./loading.component.scss";

interface LoadingComponentProps { }

const LoadingComponent: React.FC<LoadingComponentProps> = ({ }) => {

  return (
    <>
      <div className="spinner"></div>
    </>
  );
};

export default LoadingComponent;
