import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import App from "./App";

import store from "./redux/index";
import history from "./redux/history"

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};
export default AppWrapper;
