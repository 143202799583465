import React from 'react';
import Select from 'react-select';
import { SelectModel } from '../../models/select/select.model';

export type SelectComponentProps = {
    title?: string;
    options?: SelectModel[];
    onSelect?: (value: SelectModel) => void;
    default?: string;
}

const SelectComponent = (props: SelectComponentProps) => {

    const select = (inputValue: any, actionMeta: any) => {
        if(props.onSelect) {
            props.onSelect(inputValue);
        }
    }

    return (
        <div>
            <Select className="w-full"
                placeholder="همه محصولات"
                not
                onChange={select}
                value={props.options?.filter(f => f.value === props.default)}
                options={props.options ?? []}
            />
        </div>
    )
}
export default SelectComponent;