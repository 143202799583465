import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CombineReducersModel } from '..';
import { UserModel } from '../../models/user/user.model';
// import { ToastModel } from '../../models/error.model';

export interface AuthStateModel {
  isLogin: boolean;
  isLoading: boolean;
  user?: UserModel;
  captcha?: string;
}

const initialState: AuthStateModel = { isLogin: false, isLoading: false, user: undefined, captcha: undefined };

const AuthState = createSlice({
  name: 'AuthState',
  initialState,
  reducers: {
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    setCaptcha: (state, action: PayloadAction<string>) => {
      state.captcha = action.payload;
    },
  },
});

export const authState = (state: CombineReducersModel) => state.auth;
// setToast
export const { setIsLogin, startLoading, stopLoading, setUser, setCaptcha } = AuthState.actions;
export default AuthState.reducer;

