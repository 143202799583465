import react, { useState } from 'react';
import { SideBarModel } from '../../models/sidebar/sidebar-item.model';

export type SideritemComponentProps = {
    menu: SideBarModel;
    selecetd?: boolean;
    onSelect?: (menu: SideBarModel) => void;
}

const SideritemComponent = (props: SideritemComponentProps) => {

    const onClick = () => {
        if (props.onSelect) {
            props.onSelect(props.menu);
        }
    }

    const wrapper = "active:bg-green-700 hover:bg-primary-hover hover:bg-primary hover:text-white transition rounded flex flex-row w-full py-3 cursor-pointer items-center";

    return (
        <div onClick={onClick} className={wrapper + (props.selecetd === true ? " bg-primary text-white" : "")}>
            <div className="flex flex-row mr-3">
                <div>
                    <img src={props.selecetd === true ? props.menu.icon_active : props.menu.icon }></img>
                </div>
                <div className="mr-2">
                    <p className="font-bold">{props.menu.title}</p>
                </div>
            </div>
        </div>

    )
}
export default SideritemComponent;