import React from 'react';

export type LogoComponentProps = {

}

const LogoComponent = (props: LogoComponentProps) => {
    return (
        <div className="text-center">
            <img className="inline-block" src="imags/logo.svg"></img>
        </div>
    )
}
export default LogoComponent;