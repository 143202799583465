import { FilterModel } from "../../models/filter/filter.model"

export const filterName = (filter?: FilterModel) => {
    
    if(!filter) return "هفت روز گذشته";

    switch (filter.range) {
        case "today":
            return "روز جاری"
        case "month":
            return "ماه جاری"
        case "last_month":
            return "ماه گذشته"
        case "year":
            return "سال گذشته"
        case "custom":
            return ("از تاریخ " + (filter?.start_date) + " تا تاریخ " + (filter?.end_date))
        default:
            return "هفت روز گذشته"
    }
}