import React, { CSSProperties } from 'react';

export type HeadingComponentProps = {
    title?: string;
    fontSize?: number;
    color?: string;
    unit?: string;
    align?: "text-center" | "text-left" | "text-right" | undefined;
}

const HeadingComponent = (props: HeadingComponentProps) => {

    const style: CSSProperties = { fontSize: props.fontSize ?? 20, color: props.color };

    return (
        <p style={style} className={props.align}><b>{props.title}</b><span className='mr-2' style={{fontSize: '18px'}}>{props.unit}</span></p>
    )
}
export default HeadingComponent;