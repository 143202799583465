import React, { useEffect } from 'react';

import './styles/index.css';
import './styles/tailwind.css';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LoginPage from './pages/login/login.page';
import SplashPage from './pages/splash/splash.page';
import DashboardPage from './pages/dashboard/dashboard.page';
import LoadingComponent from './components/loading/loading.component';
import { useDispatch, useSelector } from 'react-redux';
import { globalState, resetModal, setSelectedMenu } from './redux/slice/global.slice';
import ModalComponent from './components/modal/modal.component';
import { UserHelper } from './helper/user.helper';
import history from './redux/history';
import CustomersPage from './pages/customers/customers.page';
import { UserModel } from './models/user/user.model';
import { setUser } from './redux/slice/auth.slice';
import { CustomersRoute, DashboardRoute, LoginRoute } from './constant/route.constant';
import ModalContainer from './components/ModalContainer/modal-container';
import { filterThunk } from './redux/thunk/filter.thunk';

const App = () => {

  const { isLoading, modal, filter, selectedProduct } = useSelector(globalState)
  const dispatch = useDispatch();

  const routes = [
    {
      path: LoginRoute,
      component: <LoginPage />
    },
    {
      path: DashboardRoute,
      component: <DashboardPage />
    },
    {
      path: CustomersRoute,
      component: <CustomersPage />
    },
    {
      path: "/",
      component: <LoginPage />
    },
  ]

  useEffect(() => {

    const user = new UserHelper();
    const isLogin = user.getAccessToken();
    const userData = user.getUser();

    if (!isLogin || !userData) {
      history.replace(LoginRoute);
      return;
    }
    dispatch(setUser(JSON.parse(userData) as UserModel));
    
  }, [])

  const resetModel = () => {
    dispatch(resetModal());
  }

  return (
    <>
      {isLoading && <LoadingComponent />}
      { modal && <ModalComponent className="sm:w-full" title={modal.title} message={modal.message} size={modal.size} close={resetModel} children={modal.children} />}
      {/* {modal && modal.children && (
        <ModalContainer>
          {modal.children}
        </ModalContainer>
      )} */}
      <div className="App">
        <Switch>
          {
            routes.map((route, index) => {
              return <Route key={index} path={route.path}>{route.component}</Route>
            })
          }
        </Switch>
      </div>
    </>
  );
}

export default App;
