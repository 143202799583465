import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CombineReducersModel } from '..';
import { CustomerModel } from '../../models/customer/customer.model';
import { ProductModel } from '../../models/product/product.model';
import { ReportModel } from '../../models/report/report.model';
// import { ToastModel } from '../../models/error.model';

export interface ProductStateModel {
  products?: ProductModel[];
  reports?: ReportModel;
  customers?: CustomerModel[];
  customer_total?: number;
}

const initialState: ProductStateModel = { products: [], reports: undefined, customers: [] };

const ProductState = createSlice({
  name: 'ProductState',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductModel[]>) => {
      state.products = action.payload;
    },
    setReports: (state, action: PayloadAction<ReportModel>) => {
      state.reports = action.payload;
    },
    setCustomers: (state, action: PayloadAction<{customers: CustomerModel[], count: number}>) => {
      state.customers = action.payload.customers;
      state.customer_total = action.payload.count;
    },
  },
});

export const productState = (state: CombineReducersModel) => state.products;
// setToast
export const { setProducts, setReports, setCustomers } = ProductState.actions;
export default ProductState.reducer;

