import React, { useState } from 'react';

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, Day, DayValue } from 'react-modern-calendar-datepicker';

type CalenderProps = {
  onSelect?: (date: string) => void;
}

const Calender = (props: CalenderProps) => {

  const [selectedDay, setSelectedDay] = useState<DayValue>();

  const select = (value: DayValue) => {
    setSelectedDay(value);
    if(props.onSelect) {
      props.onSelect(value?.year + "/" + value?.month + "/" + value?.day);
    }
  }

  return (
    <Calendar
      value={selectedDay}
      onChange={select}
      shouldHighlightWeekends
      locale="fa" // add this
    />

  )
}

export default Calender;
