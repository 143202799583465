import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../components/button/button.component';
import HeadingComponent from '../../components/heading/heading.component';
import InputComponent from '../../components/input/input.component';
import LogoComponent from '../../components/logo/logo.component';
import { authState } from '../../redux/slice/auth.slice';
import { setModal } from '../../redux/slice/global.slice';
import { changeName, testState } from '../../redux/slice/test.slice';
import { captchaRequest, loginRequest } from '../../redux/thunk/auth.thunk';

const LoginPage = () => {

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [captchaValue, setCaptchaValue] = useState<string>("");

  const dispatch = useDispatch();
  const { isLoading, captcha } = useSelector(authState)


  useEffect(() => {
    captchaRefresh();
  }, [])

  useEffect(() => {
    setCaptchaValue("");
  }, [captcha])

  const login = () => {

    if (isLoading === true) {
      dispatch(setModal({ message: "لطفا تا اتمام درخواست صبور باشید." }))
      return;
    }

    if (username === "") {
      dispatch(setModal({ message: "لطفا نام کاربری خود را وارد کنید.", title: "خطا" }))
      return;
    }

    if (password === "") {
      dispatch(setModal({ message: "لطفا کلمه عبور خود را وارد کنید." }))
      return;
    }

    if (captchaValue === "") {
      dispatch(setModal({ message: "لطفا کلید امنیتی را وارد کنید." }))
      return;
    }

    dispatch(loginRequest(username, password, captchaValue));

  }

  const captchaRefresh = () => {
    dispatch(captchaRequest());
  }

  const wrapper = "flex flex-col justify-center items-center h-screen -mt-10";
  const box = "bg-white box-content rounded p-10 space-y-7 bg-Gray-400 max-w-screen-md w-96 p-4 border mx-auto mt-10"

  return (
    <div className={wrapper}>
      <LogoComponent />
      <div className={box}>
        <HeadingComponent fontSize={30} align="text-center" title="ورود" />
        <InputComponent onChange={(value: string) => setUsername(value)} title="نام کاربری" />
        <InputComponent type="password" onChange={(value: string) => setPassword(value)} title="کلمه عبور" />
        <InputComponent value={captchaValue} className="text-center" type="number" onChange={(value: string) => setCaptchaValue(value)} title="کلید امنیتی را وارد کنید." />
        <div className="flex items-center justify-center">
          <img src={captcha} alt="" />
          <img src="imags/refresh.svg" alt="" className="cursor-pointer" onClick={captchaRefresh} />
        </div>
        <ButtonComponent className="rounded-full" loading={isLoading} onClick={login} title="ورود" />
      </div>
    </div>
  )
}

export default LoginPage;