import react from 'react';
import "./table.scss";

export type Model = {
    display_name?: string;
    product_name?: string;
    price?: string;
    image?: string;
    date?: string;
    // src?: string;
}
export type TableComponenttype = {
    model?: Model[]
}
const TableComponent = (props: TableComponenttype) => {
    return (
        <div className="table">
            <div className="row-title">
                <div className="grid grid-cols-1 gap-1 md:grid md:grid-cols-5 flex justify-center items-center">
                    {/* <div></div> */}
                    <div>
                        <p className="titletable">
                            <b>هنرمند</b>
                        </p>
                    </div>
                    <div className="col-span-2">
                        <p className="titletable">
                            <b>محصول</b>
                        </p>
                    </div>
                    <div className="col-span-1 text-center">
                        <p className="titletable">
                            <b>تاریخ</b>
                        </p>
                    </div>
                    <div className="text-center">
                        <p className="titletable">قیمت</p>
                    </div>
                </div>
            </div>

            {props.model && props.model.map(item => {
                return (
                    // cursor-pointer
                    <div className="row-table relative hover:bg-gray-light transition p-2">
                        <div className="grid grid-cols-1 gap-1 md:grid md:grid-cols-5 flex justify-center items-center">
                            <div className="flex justify-start items-center">
                                <div className="overflow-auto" dangerouslySetInnerHTML={{ __html: item.image ?? "" }} style={{ width: 40 }}></div>
                                <p className="matn-of-table mr-3">
                                    <b>{item.display_name}</b>
                                </p>
                            </div>
                            <div className="col-span-2"><p className="matn-of-table">
                                <b>{item.product_name}</b>
                            </p>
                            </div>
                            <div className="col-span-1">
                                <p className="matn-of-table text-center">{item.date}</p>
                            </div>
                            <div className="flex justify-center">
                                <p className="matn-of-table text-center">{item.price}</p>
                            </div>
                        </div>
                        {/* <img className="imges-last-sell-of-table absolute left-0 top-3" src="imags/chevron.svg" /> */}
                    </div>
                )
            })}
        </div>
    )
}

export default TableComponent;