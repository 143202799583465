import { ACCESSTOKEN } from './../constant/auth.constant';
export class UserHelper {

    private _label_token = ACCESSTOKEN;
    private _label_user = "user";

    /**
     * توکن دریافت شده را ذخیره می‌کند.
     * @param token 
     */
    setAccessToken = (token: string) => {
        localStorage.setItem(this._label_token, token);
    }

    /**
     * توکن که در هنگام ورود ذخیره شده است را برمیگرداند
     * @returns 
     */
    getAccessToken = (): string => {
        return localStorage.getItem(this._label_token) ?? "";
    }

    /**
     * تمام اطلاعات ذخیره شده را حذف میکند
     */
    clearInformation = () => {
        localStorage.clear();
    }

    /**
     * اطلاعات کاربر را ذخیره میکند.
     * @param user 
     */
    setUser = (user: string) => {
        localStorage.setItem(this._label_user, user);
    }

    /**
     * اطلاعات ذخیره شده کاربر را برمیگرداند
     * @returns 
     */
    getUser = (): string => {
        return localStorage.getItem(this._label_user) ?? ""
    }

    

}