import { UserHelper } from './../../helper/user.helper';
import AxiosHelper from "../../axios/axios.helper";
import { CAPTCHAURL, LOGIN_URL } from "../../axios/url.helper";
import { LoginModel } from "../../models/login/login.model";
import ResponseModel from "../../models/response.model";
import { setCaptcha, setIsLogin, setUser, startLoading, stopLoading } from "../slice/auth.slice";
import { startLoading as startLoadingGlobal, stopLoading as stopLoadingGlobal } from "../slice/global.slice";
import { setModal } from '../slice/global.slice';
import history from '../history';
import { CaptchaModel } from '../../models/login/captcha.model';

export function loginRequest(username: string, password: string, captcha: string) {

    return async (dispatch: any) => {

        dispatch(startLoading());

        let param = {
            "username": username,
            "password": password,
            "captcha": captcha
        }
        try {
            let response = await AxiosHelper.post<ResponseModel<LoginModel>>(LOGIN_URL, param);
            if (response.status === 200) {
                const userHelper = new UserHelper();
                userHelper.setAccessToken(response.data.data?.access_token ?? "");
                const user = response.data.data?.user;
                userHelper.setUser(JSON.stringify(user));
                dispatch(setUser(user ?? {}));

                dispatch(setIsLogin(true));
                // move to new route
                history.push("/dashboard");
            }

        } catch (error) {
            // if(error.response.status === 406) {
            //     dispatch(captchaRequest());
            // }
            dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            dispatch(captchaRequest());
            dispatch(stopLoading());
        }
    };
}

export function captchaRequest() {

    return async (dispatch: any) => {

        // dispatch(startLoadingGlobal());

        try {
            let response = await AxiosHelper.get<ResponseModel<string>>(CAPTCHAURL);
            if (response.status === 200) {
                const data = response.data;
                dispatch(setCaptcha(data.data ?? ""))
            }

        } catch (error) {
            dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            dispatch(stopLoadingGlobal());
        }
    };

}