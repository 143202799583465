import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CombineReducersModel } from '..';
import { ModalModel } from '../../models/modal.model';
import { SelectModel } from '../../models/select/select.model';
import { SideBarModel } from '../../models/sidebar/sidebar-item.model';
// import { ToastModel } from '../../models/error.model';

export interface TestStateModel {
  name?: string;
}

const initialState: TestStateModel = { name:"amasdasdasdin" };

const TestState = createSlice({
  name: 'TestState',
  initialState,
  reducers: {
    changeName: (state, action: PayloadAction<{name: string, family: number}>) => {
      state.name = action.payload.name;
    }

    // setProducts: (state, action: PayloadAction<ProductModel[]>) => {
    //   state.products = action.payload;
    // },
    
  },
});

export const testState = (state: CombineReducersModel) => state.test;
// setToast
export const { changeName } = TestState.actions;
export default TestState.reducer;

