import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomersRoute, DashboardRoute, LoginRoute } from '../../constant/route.constant';
import { UserHelper } from '../../helper/user.helper';
import { SideBarModel } from '../../models/sidebar/sidebar-item.model';
import history from '../../redux/history';
import { globalState, resetModal, setModal } from '../../redux/slice/global.slice';
import { setSelectedMenu } from '../../redux/slice/global.slice';
import ExitComponent from '../exitComponent/ExitComponent';
import FilterComponent from '../FilterComponent/FilterComponent';
import LogoTextComponent from '../logo/logo-text.component';
import SideritemComponent from './slider-item.component';

const menu: SideBarModel[] = [
    {
        title: "داشبورد",
        // name: DashboardRoute,
        route: DashboardRoute,
        icon: "imags/dashboard.svg",
        icon_active: "imags/dashboard-white.svg",
        active: true
    },
    {
        title: "سفارش‌ها",
        // name: CustomersRoute,
        route: CustomersRoute,
        icon: "imags/customers.svg",
        icon_active: "imags/customers-white.svg",
        active: false
    },
    {
        title: "خروج",
        // name: "exit",
        route: LoginRoute,
        icon: "imags/file_blank_outline.png",
        active: false
    }
]

const SiderComponent = () => {

    // const [selected, selectedName] = useState<SideBarModel>(menu[0]);
    const dispatch = useDispatch();
    const { selectedMenu } = useSelector(globalState);

    const exit = () => {
        const user = new UserHelper();
        user.clearInformation();
        history.replace("/login");
        dispatch(resetModal());
    }

    const selecetedMenu = (menu: SideBarModel) => {

        if (menu.route === LoginRoute) {
            dispatch(setModal({ children: <ExitComponent title="آیا از خروج مطمئن هستید؟" onExit={exit} onCancel={() => dispatch(resetModal())} /> }))
            return;
        }

        if (menu.route) history.push(menu.route);
        dispatch(setSelectedMenu(menu.route ?? ""));
    }

    return (
        <div className="space-y-2 bg-white p-5">
            <LogoTextComponent
                heading="بانی‌کستر"
                description="اموزش و معرفی هنرمندان سینما و تئاتر"
            />
            <div className="h-1"></div>
            {
                menu.map(item => {
                    return (
                        <SideritemComponent onSelect={selecetedMenu} selecetd={item.route === selectedMenu} menu={item} />
                    )
                })
            }
        </div>
    )
}
export default SiderComponent;