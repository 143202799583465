import React from 'react';
import Headingcomponent from '../heading/heading.component';

export type Model = {
    title?: string;
    subtitle?: string;
    unit?: string;
}

export type ReportboxsComponentProps = {
    model?: Model[]
    colorHedingComponentMustChange?: string;
}

const ReportboxsComponent = (props: ReportboxsComponentProps) => {
    return (
        <div className="flex flex-col md:flex md:flex-row">
            {props.model && props.model.map((item, index) => {
                return (
                    <div className="w-full">
                        <div className={((props.model?.length === index + 1) ? "" : "ml-1 ") +`p-3 border bg-white rounded border relative`}>
                            <div className="bg-primary rounded-full w-1 h-full absolute right-0 top-0"></div>
                            <div className="grid grid-cols-1 gap-4 pr-3">
                                <div className="">
                                    <Headingcomponent fontSize={30} title={item.title} unit={item.unit} />
                                    <Headingcomponent color={props.colorHedingComponentMustChange} title={item.subtitle} />
                                </div>
                                {/* <div className="mt-4 flex justify-end items-center">
                                    <img className="w-auto h-11" src="imags/Button Icon Light.png"></img>
                                </div> */}
                            </div>

                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default ReportboxsComponent;