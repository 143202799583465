import React from 'react';
import SpinerComponent from '../spiner/spiner.component';

export type ButtonComponentProps = {
    title?: string;
    color?: string;
    onClick?: () => void;
    loading?: boolean;
    /**
     * extra className
     */
    className?: string;

    /**
     * background button
     */
    bg?: string;

    disabled?: boolean;
}

const ButtonComponent = (props: ButtonComponentProps) => {

    const click = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    const className = ["relative flex flex-row justify-center transition-colors focus:outline-none w-full py-2 px-6 "];

    className.unshift(props.className ?? "");
    
        if(props.bg) {
            className.unshift(props.bg ?? "");
        } else {
            className.unshift("hover:bg-primary-hover bg-primary text-white" ?? "");
        }

    if(props.disabled === true) {
        className.push("cursor-not-allowed")
    }

    return (
        <button
            disabled={((props.loading ?? false) || (props.disabled ?? false))}
            onClick={click}
            style={{ color: props.color }}
            className={className.join(" ")}
        >
            {props.title}
            {/* <SpinerComponent /> */}
            {props.loading === true && <div className="absolute left-10 top-3 mr-10"><SpinerComponent /></div>}
        </button>
    )
}

export default ButtonComponent;