import { ProductModel } from './../../models/product/product.model';
import AxiosHelper from "../../axios/axios.helper";
import ResponseModel from "../../models/response.model";
import { setModal, startLoading, stopLoading } from '../slice/global.slice';
import { setProducts } from '../slice/product.slice';
import { PRODUCTLIST_URL } from '../../axios/url.helper';

export function productsRequest() {

    return async (dispatch: any) => {

        dispatch(startLoading());

        try {
            let response = await AxiosHelper.get<ResponseModel<ProductModel[]>>(PRODUCTLIST_URL);
            if (response.status === 200) {
                let data = response.data.data;
                data?.unshift({ title: "همه محصولات", id: -1 });
                dispatch(setProducts(data ?? []))
            }

        } catch (error) {
            dispatch(setModal({ message: error.response ? error.response.data.message : "خطایی رخ داده است. لطفا دوباره تلاش کنید." }))
        } finally {
            dispatch(stopLoading());
        }
    };
}