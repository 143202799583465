import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoxContainerComponent from '../../components/box-container/box-container.component';
import PaginationComponent from '../../components/pagination/pagination.component';
import TableComponent from '../../components/table/table.component';
import { CustomersRoute } from '../../constant/route.constant';
import { globalState, setSelectedMenu } from '../../redux/slice/global.slice';
import { productState } from '../../redux/slice/product.slice';
import { customersRequest } from '../../redux/thunk/customer.thunk';
import { filterThunk } from '../../redux/thunk/filter.thunk';
import { productsRequest } from '../../redux/thunk/product.thunk';
import MasterPage from '../master/master.page';

export type CustomersPageType = {

}

const CustomersPage = (props: CustomersPageType) => {

    const dispatch = useDispatch();
    const { customers, customer_total } = useSelector(productState);
    const { selectedProduct, filter } = useSelector(globalState);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {

        dispatch(setSelectedMenu(CustomersRoute));

        /**
         * درخواست لیست محصولات
         */
        if (!customers) {
            dispatch(productsRequest());
        }

        _customersRequest();

    }, [])


    const _customersRequest = (page = 0) => {
        /**
         * دریافت لیست سفارشات ثبت شده
         */
        // const product_id = Number(selectedProduct?.value);
        // dispatch(customersRequest(page, 20, !!product_id ? product_id : -1))
        dispatch(filterThunk(filter, selectedProduct, page))
    }

    const onNext = () => {
        setPage(page + 1);
        _customersRequest(page + 1);
    }

    const onPrev = () => {
        setPage(page - 1);
        _customersRequest(page - 1);
    }


    return (
        <MasterPage isCustomer={true} limit={30} page={page}>

            <BoxContainerComponent paginationComponent="1" src="imags/drag.png" secondHeader="سفارشات ثبت شده در بانی کستر" header="لیست سفارشات">
                <TableComponent model={
                    customers && customers.map((customer, index) => {
                        const product = (customer.products && customer.products?.length >= 0) ? customer.products[0] : {};
                        return {
                            display_name: customer.user?.display_name,
                            product_name: product.name,
                            price: (Number(product.price ?? "0") * (product.count ?? 1)).toLocaleString() + " تومان",
                            image: customer.user?.image,
                            date: product.date
                        }
                    })
                } />

                <div className="w-full bg-white mt-8 mb-8 ">
                    <PaginationComponent onNext={onNext} onPrev={onPrev} page={page} total={customer_total} />
                </div>

            </BoxContainerComponent>

        </MasterPage>
    )
}

export default CustomersPage;